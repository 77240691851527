import { Container, Row, Col } from 'bootstrap-4-react/lib/components/layout';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from "react-icons/md";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';

function BasicExample() {
  const [data, setData] = useState(null);
  const [metatage, setMetaData] = useState();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company_name: '',
    phone_number: '',
    model: '',
    query: '',
    message: '',
    is_agree: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setFormData({ ...formData, is_agree: checked }); // Update checkbox state based on whether it's checked
  };

  const handleSubmits = (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Start submission

    axios.post('https://admin.autorentmotorcycle.com/api/postContactus', formData)
      .then(res => {
        setData(res.data);
        toast(res.data.message);
        // Reset form data including checkbox state
        setFormData({
          name: '',
          email: '',
          phone_number: '',
          company_name: '',
          message: '',
          model: '',
          query: '',
          is_agree: false, // Reset checkbox
        });
      })
      .catch(err => {
        const errors = err.response?.data?.data; // Safely access nested data
        if (errors) {
          Object.values(errors).forEach(errorArray => {
            errorArray.forEach(errorMessage => {
              toast(errorMessage);
            });
          });
        } else {
          toast("An unexpected error occurred."); // Fallback error message
        }
        console.error(err);
      })
      .finally(() => {
        setIsSubmitting(false); // End submission
      });
  };

  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getSeoMetaTagsBySlug/contact')
      .then(res => { setMetaData(res.data.data); });
  }, []);

  return (
    <>
      {metatage && (
        <Helmet>
          <title>{metatage.seo.meta_title}</title>
          <meta name="description" content={metatage.seo.meta_description} />
          <meta name="keywords" content={metatage.seo.meta_keywords} />
          <link rel="canonical" href={metatage.seo.canonical} />
          <meta name="robots" content={metatage.seo.robots} />
          <meta property="og:title" content={metatage.seo.og_title} />
          <meta property="og:description" content={metatage.seo.og_description} />
          <meta property="og:image" content={metatage.seo.og_image} />
          <meta property="og:url" content={metatage.seo.og_url} />
          <meta property="og:type" content={metatage.seo.og_type} />
          <meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
      )}
      <Container fluid>
        <ToastContainer />
        <Row>
        </Row>
        <Row className="contact-row mb-5">
          <h4 className='mb-5 text-center mt-5'>Get In Touch</h4>
          <Col lg={6} md={6}>
            <Form onSubmit={handleSubmits}>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      onChange={handleInputChange}
                      name='name'
                      value={formData.name}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="company_name">
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      onChange={handleInputChange}
                      name='company_name'
                      value={formData.company_name}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="phone_number">
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name='phone_number'
                      value={formData.phone_number}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="model">
                    <Form.Control
                      type="text"
                      placeholder="Models"
                      name='model'
                      value={formData.model}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleInputChange}
                    name='query'
                    value={formData.query}
                  >
                    <option value="">Query</option>
                    <option value="1">General</option>
                    <option value="2">Lease</option>
                    <option value="3">Breakdown</option>
                  </select>
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Text Box"
                      style={{ height: '120px' }}
                      name='message'
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="privacyCheckbox"
                      name="is_agree"
                      checked={formData.is_agree} // Bind checkbox state
                      onChange={handleCheckboxChange} // Handle checkbox change
                      className="form-check-input"
                    />
                    <label className="form-check-label mb-4" htmlFor="privacyCheckbox" style={{color:"white"}}>
                      I agree to Gulf Great Sand's privacy statement and understand how my personal data will be processed.
                    </label>
                  </div>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Form>
          </Col>
          <Col lg={2} md={3} xs={12} className="contact-us">
            <div className='contact-us-2'>
              <Row className="card-position">
                <Col lg={12}>
                  <Card className='text-center card-position-card' style={{ padding: '45px' }}>
                    <span>
                      <BsFillTelephoneFill fontSize={'40px'} color='white' />
                    </span>
                    <h5 style={{ fontSize: '18px', color: 'white' }}>+971 600 549 993</h5>
                  </Card>
                </Col>
              </Row>
              <Row className="card-position">
                <Col lg={12}>
                  <Card className='text-center card-position-card card-2' style={{ position: 'relative', top: '-5%' }}>
                    <span>
                      <MdEmail fontSize={'40px'} color='white' style={{ marginTop: '10%' }} />
                    </span>
                    <h5 style={{ fontSize: '18px', color: 'white', marginTop: '-30%' }}>info@motorcyclerental.com</h5>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BasicExample;
